import React, { useEffect, useState } from 'react'
import { text } from '../../ultils/constant'
import { Province, ItemSidebar, RelatedPost } from '../../components'
import { List, Pagination } from './index'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { getPostsLimit } from '../../store/actions'
import { useSearchParams } from 'react-router-dom';
const Homepage = () => {
    const dispatch = useDispatch();
    const { categories, prices, areas } = useSelector(state => state.app);
    const [searchParams] = useSearchParams();
    const categoryCode = searchParams.get('categoryCode') || '';
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        const params = categoryCode ? { categoryCode } : {};
        dispatch(getPostsLimit(params));
    }, [dispatch, categoryCode]);

    useEffect(() => {
        let page = searchParams.get('page');
        if (page) {
            setCurrentPage(+page);
        } else {
            setCurrentPage(1);
        }
    }, [searchParams]);

    return (
        <div className='w-full flex flex-col gap-3'>
            <div>
                <h1 className='text-[28px] font-bold'>{text.HOME_TITLE}</h1>
                <p className='text-base text-gray-700'>{text.HOME_DESCRIPTION}</p>
            </div>
            <Province />
            <div className='w-full flex flex-col gap-4'>
                <ItemSidebar isDouble={true} type='priceCode' content={prices} title='Xem theo giá' />
                <ItemSidebar isDouble={true} type='areaCode' content={areas} title='Xem theo diện tích' />
            </div>

            <div className='w-full flex gap-3'>
                <div className='w-[75%]'>
                    <List isFavoritePostPage={false} categoryCode={categoryCode} />
                    <Pagination />
                </div>
                <div className='w-[25%] flex flex-col gap-4 justify-start items-center'>
                    <RelatedPost key={`${categoryCode}-${currentPage}`} />
                </div>
            </div>
        </div>
    );
};


export default Homepage