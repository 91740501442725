import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Navigate, Outlet } from 'react-router-dom'
import { path } from '../../ultils/constant'
import { Header, Sidebar } from './'
import { useState } from 'react';
import { Drawer, Button } from 'antd';
import { useMediaQuery } from 'react-responsive';
import SidebarContent from '../public/SidebarContent'
import { NavLink } from 'react-router-dom'
import { formatVietnameseToString } from '../../ultils/Common/formatVietnameseToString'
const activeStyle = 'hover:bg-gray-200 flex  rounded-md items-center gap-2 py-2 font-bold bg-gray-200'
const notActiceStyle = 'hover:bg-gray-200 flex  rounded-md items-center gap-2 py-2 cursor-pointer'

const desiredOrder = [
    'Thuê phòng trọ',
    'Thuê nhà nguyên căn',
    'CHDV - CC',
    'Mặt bằng , văn phòng',
    '+1000 GROUP Facebook'
];

const System = () => {

    const dispatch = useDispatch()
    const { isLoggedIn } = useSelector(state => state.auth);
    const [drawerVisible, setDrawerVisible] = useState(false);
    const isMdOrLower = useMediaQuery({ query: '(max-width: 768px)' });
    const { currentData } = useSelector((state) => state.user);
    const isAdmin = currentData?.isAdmin === 1;
    const { categories } = useSelector(state => state.app);
    if (!isLoggedIn) return <Navigate to={`/${path.LOGIN}`} replace={true} />;
    const sortedCategories = categories?.slice().sort((a, b) => {
        return desiredOrder.indexOf(a.value) - desiredOrder.indexOf(b.value);
    });
    return (
        <div className='w-full h-screen flex flex-col items-center'>
            <Header onMenuClick={() => setDrawerVisible(true)} />
            <div className='flex w-full h-screen flex-auto'>
                {!isMdOrLower ? <Sidebar /> : (
                    <Drawer
                        title="Menu"
                        placement="left"
                        closable={false}
                        onClose={() => setDrawerVisible(false)}
                        visible={drawerVisible}
                        width={256}
                    >
                        <SidebarContent currentData={currentData} isAdmin={isAdmin} dispatch={dispatch} />

                        {sortedCategories?.length > 0 && sortedCategories.map(item => (
                            <div key={item.code} >
                                <NavLink
                                    to={`/${formatVietnameseToString(item.value)}`}
                                    className={({ isActive }) => isActive ? activeStyle : notActiceStyle}
                                >
                                    {item.value}
                                </NavLink>
                            </div>
                        ))}

                    </Drawer>
                )}
                <div className='flex-auto bg-white shadow-md h-full p-4 overflow-y-scroll'>
                    <Outlet />
                </div>
            </div>
        </div>
    );
};
export default System