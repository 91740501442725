import React, { memo } from 'react'

const Select = ({ label, options, value, setValue, type, reset, name, invalidFields, setInvalidFields }) => {
    const handleErrorText = () => {
        let nameInvalid = invalidFields?.find(item => item.name === name);
        let addressInvalid = invalidFields?.find(item => item.name === 'address');
        return `${nameInvalid ? nameInvalid.message : ''}` || `${addressInvalid ? addressInvalid.message : ''}`;
    };

    const getOptionLabel = (item) => item.name;
    const getOptionValue = (item) => item.code;

    return (
        <div className='flex flex-col gap-2 flex-1'>
            <label className='font-medium' htmlFor={`select-${type || name}`}>{label}</label>
            <select
                value={reset ? '' : value}
                onChange={(e) => setValue(e.target.value)}
                id={`select-${type || name}`}
                className='outline-none border border-gray-300 p-2 rounded-md w-full'
                onFocus={() => setInvalidFields([])}
            >
                <option value="">{`--Chọn ${label}--`}</option>
                {options?.map(item => (
                    <option
                        key={getOptionValue(item)}
                        value={getOptionValue(item)}
                    >
                        {getOptionLabel(item)}
                    </option>
                ))}
            </select>
            {invalidFields && <small className='text-red-500'>
                {handleErrorText()}
            </small>}
        </div>
    );
};


export default memo(Select)