import axiosConfig from '../axiosConfig'
import axios from 'axios'

export const apiGetPosts = () => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'get',
            url: '/api/v1/post/all',
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiGetPostsLimit = (query) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'get',
            url: '/api/v1/post/limit',  // Ensure this URL is correct
            params: query
        });
        resolve(response);
    } catch (error) {
        reject(error);
    }
});
export const apiGetPostsLimitAdmin = (query) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'get',
            url: `/api/v1/post/limit-admin`,
            params: query
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiGetNewPosts = () => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'get',
            url: `/api/v1/post/new-post`,
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiUploadImages = (images) => new Promise(async (resolve, reject) => {
    try {
        const formData = new FormData();
        images.forEach(image => {
            formData.append('images', image);  // Make sure the key matches the backend route
        });

        const response = await axiosConfig({
            method: 'post',
            url: '/api/v1/post/images',  // Adjust to your backend route
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        resolve(response.data);
    } catch (error) {
        reject(error);
    }
});

export const apiUploadVideos = (videos) => {
    return new Promise(async (resolve, reject) => {
        try {
            const formData = new FormData();
            videos.forEach(video => {
                formData.append('videos', video);  // Ensure the key matches what the backend expects
            });

            const response = await axiosConfig({
                method: 'post',
                url: '/api/v1/post/videos',
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            console.log('Upload response:', response);  // Log response
            resolve(response.data);  
        } catch (error) {
            if (error.response) {
                reject(new Error(`Error: ${error.response.data.message || 'Upload failed'}`));
            } else if (error.request) {
                reject(new Error('No response from the server. Please check your connection.'));
            } else {
                reject(new Error(`Request failed: ${error.message}`));
            }
        }
    });
};



export const apiCreatePost = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'post',
            url: `/api/v1/post/create-new`,
            data: payload,
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiUpdatePost = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'put',
            url: `/api/v1/post/update-admin`,
            data: payload,
        })
        resolve(response)
    } catch (error) {
        reject(error);
    }
});


export const apiUpdatePostAdmin = (payload, postId) => new Promise(async (resolve, reject) => {
    try {
        // Get the auth data from localStorage
        const authData = JSON.parse(localStorage.getItem('persist:auth'));

        // Ensure authData and token are correctly retrieved
        if (!authData || !authData.token) {
            return reject(new Error('No authentication data found.'));
        }

        // Parse the token string from the authData
        const token = JSON.parse(authData.token);

        if (!token) {
            return reject(new Error('Token is missing.'));
        }

        // Now make the request with the correct Authorization header
        const response = await axiosConfig({
            method: 'put',
            url: `/api/v1/post/update-admin/${postId}`,
            data: payload,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Z-Key',
                'Access-Control-Allow-Methods': 'GET, HEAD, POST, PUT, DELETE, OPTIONS'
            }
        });
        resolve(response.data);
    } catch (error) {
        reject(error);
    }
});



export const apiDeletePost = (postId) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'delete',
            url: `/api/v1/post/delete/${postId}`,
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})

export const apiUpdatePostLikeStatus = (postId, isLiked) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'put',
            url: `/api/v1/post/update-like-status/${postId}`, // Assuming your backend API route to update like status follows this pattern
            data: { isLiked }, // Sending the updated like status in the request body
        });
        resolve(response);
    } catch (error) {
        reject(error);
    }
});

