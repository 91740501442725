import React, { useEffect, useState } from "react";
import { Button, Item } from "../../components";
import { getPosts, getPostsLimit, resetPosts } from "../../store/actions/post";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams, useNavigate } from "react-router-dom";
import { getFavoritePostsByUserId } from "../../services/favoritePost";
import { usePagination } from "./PaginationProvider";

const formatDateTime = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
};

const List = ({ categoryCode, isFavoritePostPage }) => {
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const { posts } = useSelector(state => state.post);
    const { currentData } = useSelector(state => state.user);
    const [currentPosts, setCurrentPosts] = useState([]);
    const [page, setPage] = useState(parseInt(searchParams.get('page')) || 1);
    const [timestamp, setTimestamp] = useState('');

    useEffect(() => {
        const now = new Date();
        setTimestamp(formatDateTime(now));
    }, []);

    // Fetch favorite posts
    useEffect(() => {
        let isApiSubscribed = true;
        const fetchFavoritePosts = async () => {
            const userId = currentData?.id;
            if (userId && isApiSubscribed) {
                const favoritePosts = await getFavoritePostsByUserId(userId);
                if (isApiSubscribed) {
                    const uniqueFavoritePosts = favoritePosts.data
                        .filter((post, index, self) =>
                            index === self.findIndex((p) => p.id === post.id)
                        );
                    setCurrentPosts(uniqueFavoritePosts);
                }
            }
        };

        if (isFavoritePostPage) {
            fetchFavoritePosts();
        } else {
            // Reset currentPosts when leaving the favorite posts page
            setCurrentPosts([]);
        }

        return () => {
            isApiSubscribed = false;
        };
    }, [currentData, isFavoritePostPage]);

    // Fetch all posts with search params and categoryCode
    useEffect(() => {
        let params = []
        for (let entry of searchParams.entries()) {
            params.push(entry)
        }
        let searchParamsObject = {}
        params?.forEach(i => {
            if (Object.keys(searchParamsObject)?.some(item => item === i[0])) {
                searchParamsObject[i[0]] = [...searchParamsObject[i[0]], i[1]]
            } else {
                searchParamsObject = { ...searchParamsObject, [i[0]]: [i[1]] }
            }
        })
        if (categoryCode) searchParamsObject.categoryCode = categoryCode
        dispatch(getPostsLimit(searchParamsObject))

        // Reset posts when switching pages
        return () => {
            dispatch(resetPosts());
        };
    }, [searchParams, categoryCode, dispatch, page, isFavoritePostPage]);

    // Update page state when searchParams change
    useEffect(() => {
        const newPage = parseInt(searchParams.get('page')) || 1;
        setPage(newPage);
    }, [searchParams]);
    
   const renderItems = (items) => (
    items.map((item) => {
        // Ensure item is defined before accessing its properties
        if (!item) {
            console.error("Item is undefined or null.");
            return null;
        }

        let images = [];

        // Check if images and image properties are defined
        if (item.images && item.images.image) {
            if (typeof item.images.image === 'string') {
                // Handle image URL strings (comma-separated URLs)
                images = item.images.image.split(',');
            } else if (Array.isArray(item.images.image)) {
                // Handle an array of image URLs
                images = item.images.image;
            } else if (
                typeof item.images.image === 'object' &&
                item.images.image.type === 'Buffer' &&
                Array.isArray(item.images.image.data)
            ) {
                // Convert the byte array into a string (assuming it contains a URL)
                const urlString = String.fromCharCode(...item.images.image.data);
                // Split by commas if there are multiple URLs
                images = urlString.split(',');
            } else {
                console.error("Unexpected image format:", item.images.image);
            }
        }

        return (
            <Item
                key={item.id}
                address={item.address}
                attributes={item.attributes}
                description={item.description}
                images={images}
                star={+item.star}
                title={item.title}
                user={item.user}
                id={item.id}
            />
        );
    })
);
    const displayedPosts = isFavoritePostPage ? currentPosts : posts.filter(post => post.status === 'approved');
    const uniqueDisplayedPosts = Array.from(new Set(displayedPosts.map(post => post.id)))
        .map(id => displayedPosts.find(post => post.id === id));
    const pageSize = 6;
    const paginatedPosts = uniqueDisplayedPosts.slice((page - 1) * pageSize, page * pageSize);

    return (
        <div className="w-full p-2 bg-white shadow-md rounded-md px-6">
            <div className="flex items-center justify-between my-3">
                <h4 className="text-xl font-semibold">
                    {!isFavoritePostPage ? "Danh sách tin đăng" : "Danh sách bài đăng yêu thích"}
                </h4>
                <span>Cập nhật: {timestamp}</span>
            </div>
            <div className="grid md:grid-cols-2 gap-4">
                {renderItems(paginatedPosts)}
            </div>
        </div>
    );
};

export default List;
