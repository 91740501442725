import React, { memo, useState } from 'react'
import icons from '../ultils/icons'
import { useNavigate, Link } from 'react-router-dom'
import { formatVietnameseToString } from '../ultils/Common/formatVietnameseToString'
import { path } from '../ultils/constant'

const indexs = [0, 1, 2, 3]

const { GrStar, BsBookmarkStarFill } = icons

const Item = ({ images, user, title, star, description, attributes, address, id }) => {

    const handleStar = (star) => {
        let stars = [];
        for (let i = 1; i <= +star; i++) stars.push(<GrStar className='star-item' size={18} color='yellow' />);
        return stars;
    };

    // Ensure images is parsed as an array
    let parsedImages = [];
    try {
        parsedImages = Array.isArray(images) ? images : JSON.parse(images || "[]");
    } catch (error) {
        console.error('Error parsing images:', error);
        parsedImages = [];
    }

    return (
        <div className='menu-2 w-full flex flex-col md:flex-row border-t border-orange-600 py-4'>
            <Link
                to={`${path.DETAIL}${formatVietnameseToString(title?.replaceAll('/', ''))}/${id}`}
                className='w-full md:w-2/5 flex flex-wrap gap-[2px] items-center relative cursor-pointer'
            >
                {Array.isArray(parsedImages) && parsedImages.length > 0 && parsedImages.slice(0, 4).map((image, index) => {
                    return (
                        <img key={index} src={image} alt="preview" className='w-[47%] h-[120px] object-cover' />
                    );
                })}
                <span className='bg-overlay-70 text-white px-2 rounded-md absolute left-1 bottom-4'>{`${parsedImages.length} ảnh`}</span>
            </Link>
            <div className='w-full md:w-3/5'>
                <div className='flex flex-col md:flex-row justify-between gap-4 w-full'>
                    <Link to={`${path.DETAIL}${formatVietnameseToString(title?.replaceAll('/', ''))}/${id}`} className='text-red-600 font-medium flex flex-col md:flex-row items-start md:items-center'>
                        {handleStar(+star).length > 0 && handleStar(+star).map((star, number) => {
                            return (
                                <span key={number}>{star}</span>
                            );
                        })}
                        <span className='md:ml-2'>{title}</span>
                    </Link>
                    <div className='flex justify-end'>
                        <BsBookmarkStarFill size={24} color='orange' />
                    </div>
                </div>
                <div className='my-2 flex flex-col md:flex-row items-center justify-between gap-2'>
                    <span className='font-bold flex-3 text-green-600 whitespace-nowrap overflow-hidden text-ellipsis'>{attributes?.price}</span>
                    <span className='flex-1'>{attributes?.acreage}</span>
                    <span className='flex-3 whitespace-nowrap overflow-hidden text-ellipsis'>
                        {`${address.split(',')[address.split(',').length - 2]}, ${address.split(',')[address.split(',').length - 1]}`}
                    </span>
                </div>
                <p className='text-gray-500 text-center w-full h-[50px] text-ellipsis overflow-hidden'>
                    {description}
                </p>
                <div className='flex flex-col md:flex-row items-center justify-between'>
                    <div className='flex items-center my-5'>
                        <div className='flex gap-2 items-center'>
                            <p>{user?.name}</p>
                        </div>
                    </div>
                    <div className='flex items-center my-5 justify-between'>
                        <div className='flex items-center gap-1'>
                            <button
                                type='button'
                                className='bg-blue-700 text-white p-1 rounded-md'
                            >
                                {`SĐT: ${user?.phone}`}
                            </button>
                            <a
                                className='text-blue-700 p-1 rounded-md border border-orange-400'
                                href={`https://zalo.me/${user?.zalo}`}
                                target='_blank'
                                rel='noopener noreferrer'
                            >
                                Nhắn zalo
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};


export default memo(Item)