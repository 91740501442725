import React, { useState } from 'react';
import { Drawer, Button } from 'antd';
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, Navigate, Outlet } from 'react-router-dom';
import { AiOutlineLogout, AiOutlineMenu } from 'react-icons/ai';
import anonAvatar from '../../assets/anon-avatar.png'
import menuSidebar from '../../ultils/menuSidebar'
import * as actions from '../../store/actions'
import { blobToBase64 } from '../../ultils/Common/toBase64'

const activeStyle = 'hover:bg-gray-200 flex  rounded-md items-center gap-2 py-2 font-bold bg-gray-200'
const notActiceStyle = 'hover:bg-gray-200 flex  rounded-md items-center gap-2 py-2 cursor-pointer'

const SidebarContent = ({ currentData, isAdmin, dispatch }) => (
    <div className='flex flex-col gap-6'>
        <div className='flex flex-col gap-4'>
            <div className='flex items-center gap-4'>
                <img src={blobToBase64(currentData?.avatar) || anonAvatar} alt="avatar" className='w-12 h-12 object-cover rounded-full border-2 border-white' />
                <div className='flex flex-col justify-center'>
                    <span className='font-semibold'>{currentData?.name}</span>
                    <small>{currentData?.phone}</small>
                </div>
            </div>
            <span>Mã thành viên: <small className='font-medium'>{currentData?.id?.match(/\d/g).join('')?.slice(0, 6)}</small></span>
        </div>
        <div>
            {menuSidebar.filter(item => item.text !== 'Quản lý người dùng' || isAdmin).map(item => (
                <NavLink
                    className={({ isActive }) => isActive ? activeStyle : notActiceStyle}
                    key={item.id}
                    to={item?.path}
                >
                    {item?.icon}
                    {item.text}
                </NavLink>
            ))}
            <span onClick={() => dispatch(actions.logout())} className={notActiceStyle}><AiOutlineLogout />Thoát</span>
        </div>
    </div>
);
export default SidebarContent;