import { createContext, useContext, useState } from 'react';

const PaginationContext = createContext();

export const PaginationProvider = ({ children }) => {
    const [favoritePostsCount, setFavoritePostsCount] = useState(0);

    return (
        <PaginationContext.Provider value={{ favoritePostsCount, setFavoritePostsCount }}>
            {children}
        </PaginationContext.Provider>
    );
};

export const usePagination = () => useContext(PaginationContext);
