import { Routes, Route } from 'react-router-dom'
import { Home, Login, Rental, Homepage, DetailPost, SearchDetail, Contact } from './containers/public'
import { path } from './ultils/constant'
import { System, CreatePost, EditAccount, AdminPage } from './containers/System'
import * as actions from './store/actions'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import ManagerPost from './containers/System/ManagerPost'
import FavoritePostsPage from './containers/public/FavoritePostsPage'
import { ConfigProvider } from 'antd';
import QuetViTri from './containers/public/QuetViTri'
import ManagerUser from './containers/System/ManagerUser'
function App() {
  const dispatch = useDispatch()
  const { isLoggedIn } = useSelector(state => state.auth)
  useEffect(() => {
    setTimeout(() => {
      isLoggedIn && dispatch(actions.getCurrent())
    }, 1000)
  }, [isLoggedIn])

  useEffect(() => {
    dispatch(actions.getPrices())
    dispatch(actions.getAreas())
    dispatch(actions.getProvinces())
    dispatch(actions.getDistricts())
  }, [])

  return (
    <ConfigProvider
      theme={{
        components: {
          Carousel: {
            dotWidth: 50,
            dotHeight: 5
          }
        },
      }}>
      <div className="bg-primary overflow-hidden">
        <Routes>
          <Route path="/quet-vi-tri" element={<QuetViTri />} />
          <Route path={path.HOME} element={<Home />}>

            <Route path='*' element={<Homepage />} />
            <Route path={path.LOGIN} element={<Login />} />
            <Route path={path.CHO_THUE_CAN_HO} element={<Rental />} />
            <Route path={path.CHO_THUE_MAT_BANG} element={<Rental />} />
            <Route path={path.CHO_THUE_PHONG_TRO} element={<Rental />} />
            <Route path={path.NHA_CHO_THUE} element={<Rental />} />
            <Route path={path.SEARCH} element={<SearchDetail />} />
            <Route path={path.DETAL_POST__TITLE__POSTID} element={<DetailPost />} />
            <Route path={path.CONTACT} element={<Contact />} />
            <Route path={path.YEU_THICH} element={<FavoritePostsPage />} />
          </Route>
          <Route path={path.SYSTEM} element={<System />} >
            <Route path={path.CREATE_POST} element={<CreatePost />} />
            <Route path={path.MANAGER_POST} element={<ManagerPost />} />
            <Route path={path.EIDT_ACCOUNT} element={<EditAccount />} />
            <Route path={path.ADMIN_PAGE} element={<AdminPage />} />
            <Route path={path.MANAGER_USER} element={<ManagerUser />} />
          </Route>

        </Routes>
      </div>
    </ConfigProvider>
  );
}

export default App;
