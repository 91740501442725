import React, { useCallback, useEffect, useRef, useState } from 'react';
import { User } from '../../components';
import LOGO from '../../assets/LOGO.jpeg';
import icons from '../../ultils/icons';
import { useNavigate, Link, useSearchParams } from 'react-router-dom';
import { path } from '../../ultils/constant';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../store/actions';
import menuManage from '../../ultils/menuManage';
import { Menu, Dropdown, Input } from 'antd';
import '../../index.css';
import { Button, Drawer } from 'antd';
import { NavLink } from 'react-router-dom';
import { formatVietnameseToString } from '../../ultils/Common/formatVietnameseToString'
import { MenuOutlined } from '@ant-design/icons';
const { Search } = Input;

const { FaArrowRightToBracket, AiOutlineLogout, BsChevronDown, FaRegEdit, MdOutlinePersonAddAlt, FaRegHeart, CiGift } = icons;

const desiredOrder = [
    'Thuê phòng trọ',
    'Thuê nhà nguyên căn',
    'CHDV - CC',
    'Mặt bằng , văn phòng',
    '+1000 GROUP Facebook'
];

const Header = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const headerRef = useRef();
    const { isLoggedIn } = useSelector(state => state.auth);
    const { categories } = useSelector(state => state.app);
    const categoryCode = searchParams.get('categoryCode');
    const [drawerVisible, setDrawerVisible] = useState(false);

    const goLogin = useCallback((flag) => {
        navigate('/login', { state: { flag, categoryCode } });
    }, [navigate, categoryCode]);

    useEffect(() => {
        dispatch(actions.getCategories());
    }, [dispatch]);

    useEffect(() => {
        headerRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }, [searchParams]);

    // Sort categories according to the desired order
    const sortedCategories = categories?.slice().sort((a, b) => {
        return desiredOrder.indexOf(a.value) - desiredOrder.indexOf(b.value);
    });

    // Function to show the drawer
    const showDrawer = () => {
        setDrawerVisible(true);
    };

    // Function to close the drawer
    const onClose = () => {
        setDrawerVisible(false);
    };
    return (
        <div ref={headerRef} className="fixed top-0 left-0 w-full z-50 bg-white shadow-md">
            <div className="bg-white shadow-md p-2">
                <div className="menu flex justify-between items-center space-x-2">
                    <NavLink to={`/`} className="logo text-md font-bold">
                        THAOLOANLAND.COM
                    </NavLink>
                    {/* Conditionally render the menu icon for screens lg or smaller */}
                    <div className="lg:hidden flex items-center">
                        <Button
                            type="text"
                            icon={<MenuOutlined />}
                            onClick={showDrawer}
                        />
                    </div>
                    {/* Render buttons for screens larger than lg */}
                    <div className={`${isLoggedIn ? 'lg:gap-20 lg1:gap-24 lg2:gap-28 lg3:gap-32 lg4:gap-36 lg5:gap-40 lg6:gap-40 lg7:gap-44 lg8:gap-48 lg9:gap-52 lg10:gap-52' : 'lg1:gap-3 lg2:gap-4 lg3:gap-5 lg4:gap-6 lg5:gap-7 lg6:gap-8 lg7:gap-9 lg8:gap-10 lg9:gap-11 lg10:gap-12'} 
                    hidden lg:flex  flex-wrap gap-1 items-center justify-between`}>
                        {sortedCategories?.length > 0 && sortedCategories.map(item => (
                            <div key={item.code} className='flex justify-center items-center'>
                                <NavLink to={`/${formatVietnameseToString(item.value)}?categoryCode=${item.code}`}>
                                    <Button className="text-[12px] p-1">
                                        {item.value}
                                    </Button>
                                </NavLink>
                            </div>
                        ))}
                        {!isLoggedIn && (
                            <>
                                <NavLink to={`/uu-dai?categoryCode=${categoryCode || ''}`} className="logo text-md font-bold">
                                    <Button className='text-[12px] p-1' icon={<CiGift />}>Ưu đãi</Button>
                                </NavLink>
                                <Button className='text-[12px] p-1' icon={<MdOutlinePersonAddAlt />} onClick={() => goLogin(true)}>Đăng ký</Button>
                                <Button className='text-[12px] p-1' icon={<FaArrowRightToBracket />} onClick={() => goLogin(false)}>Đăng nhập</Button>
                                <Button className='text-[12px] p-1 bg-secondary2' icon={<FaRegEdit />} onClick={() => navigate(`/he-thong/tao-moi-bai-dang?categoryCode=${categoryCode || ''}`)} type="primary">Đăng tin miễn phí</Button>
                            </>
                        )}
                    </div>
                </div>
            </div>

            {/* Drawer for small screens */}
            <Drawer
                title="Menu"
                placement="right"
                closable={true}
                onClose={onClose}
                visible={drawerVisible}
            >
                {isLoggedIn && (
                    <div className="mb-4">
                        <User />
                    </div>
                )}
                {sortedCategories?.length > 0 && sortedCategories.map(item => (
                    <div key={item.code} className='mb-2'>
                        <NavLink to={`/${formatVietnameseToString(item.value)}?categoryCode=${item.code}`} onClick={onClose}>
                            <Button className="w-full text-left">
                                {item.value}
                            </Button>
                        </NavLink>
                    </div>
                ))}
                {!isLoggedIn && (
                    <>
                        <NavLink to={`/uu-dai?categoryCode=${categoryCode || ''}`} className="block mb-2" onClick={onClose}>
                            <Button className='w-full text-left' icon={<CiGift />}>Ưu đãi</Button>
                        </NavLink>
                        <Button className='w-full text-left mb-2' icon={<MdOutlinePersonAddAlt />} onClick={() => { goLogin(true); onClose(); }}>Đăng ký</Button>
                        <Button className='w-full text-left mb-2' icon={<FaArrowRightToBracket />} onClick={() => { goLogin(false); onClose(); }}>Đăng nhập</Button>
                        <Button className='w-full text-left mb-2 bg-secondary2' icon={<FaRegEdit />} onClick={() => { navigate(`/he-thong/tao-moi-bai-dang?categoryCode=${categoryCode || ''}`); onClose(); }} type="primary">Đăng tin miễn phí</Button>
                    </>
                )}
                {isLoggedIn && (
                    <div className="flex flex-wrap gap-1 items-center">
                        {menuManage.map(item => (

                            <Link key={item.id}
                                className='w-full border px-4 py-1 rounded-md text-left flex items-center align-baseline'
                                
                                to={`${item.path}?categoryCode=${categoryCode || ''}`}>
                                {item.icon}
                                <span className="ml-2 text-[14  px]">{item.text}</span>
                            </Link>


                        ))}
                        <Button
                            className='w-full text-left flex items-center align-baseline'
                            onClick={() => {
                                dispatch(actions.logout());
                                onClose();
                            }}
                        >
                            <AiOutlineLogout />
                            <span className="ml-2 text-[14px]">Đăng xuất</span>
                        </Button>
                    </div>
                )}
            </Drawer>

            {isLoggedIn && (
                <div className='hidden lg:flex justify-between w-full relative z-10'>
                    {/* <div className='p-2 flex w-full items-center justify-center'> */}

                    <div className="flex w-full p-2 justify-between flex-wrap gap-1 items-center">
                        <User />
                        {menuManage.map(item => (
                            <Link
                                className='hover:text-blue-500 h-[30px] hover:border-blue-500 flex items-center gap-1 text-black rounded-lg border border-gray-200 px-1 py-0'
                                key={item.id}
                                to={`${item.path}?categoryCode=${categoryCode || ''}`}
                            >
                                {item.icon}
                                <span className="text-[12px]">{item.text}</span>
                            </Link>
                        ))}
                        <span
                            className='cursor-pointer h-[30px] hover:text-blue-500 hover:border-blue-500 text-black rounded-lg border border-gray-200 px-1 py-0 flex items-center gap-1'
                            onClick={() => {
                                dispatch(actions.logout());
                            }}
                        >
                            <AiOutlineLogout />
                            <span className="text-[12px]">Đăng xuất</span>
                        </span>
                    </div>
                </div>
                // </div>
            )}
        </div>
    );
};

export default Header;
