import React from 'react'
import anonAvatar from '../../assets/anon-avatar.png'
import { useSelector, useDispatch } from 'react-redux'
import menuSidebar from '../../ultils/menuSidebar'
import { NavLink } from 'react-router-dom'
import * as actions from '../../store/actions'
import { AiOutlineLogout } from 'react-icons/ai'
import { blobToBase64 } from '../../ultils/Common/toBase64'
import SidebarContent from '../public/SidebarContent'
import { Drawer } from 'antd'
const activeStyle = 'hover:bg-gray-200 flex  rounded-md items-center gap-2 py-2 font-bold bg-gray-200'
const notActiceStyle = 'hover:bg-gray-200 flex  rounded-md items-center gap-2 py-2 cursor-pointer'

const Sidebar = () => {
    const dispatch = useDispatch();
    const { currentData } = useSelector(state => state.user);
    const isAdmin = currentData?.isAdmin === 1;

    return (
        <div className='w-[256px] flex-none p-4 flex flex-col gap-6'>
            <SidebarContent currentData={currentData} isAdmin={isAdmin} dispatch={dispatch} />
        </div>
    );
};

export default Sidebar