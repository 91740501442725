import React from 'react'
import { useSelector } from 'react-redux'
import anonAvatar from '../assets/anon-avatar.png'
import { blobToBase64 } from '../ultils/Common/toBase64'
const User = () => {
    const { currentData } = useSelector(state => state.user);


    if (!currentData || Object.keys(currentData).length === 0) {
        return null; // Return null or a loading spinner if data is not yet available
    }

    return (
        <div className='flex items-center gap-2'>
            <img src={blobToBase64(currentData?.avatar) || anonAvatar} alt="avatar" className='w-[70px] object-cover rounded-full h-[70px] border-2 shadow-md border-white' />
            <div className='flex flex-col'>
                <span>Xin chào, <span className='font-semibold'>{currentData?.name}</span></span>
                <span>Mã tài khoản: <span className='font-medium'>{`${currentData?.id?.match(/\d/g).join('')?.slice(0, 6)}`}</span></span>
            </div>
        </div>
    );
};


export default User