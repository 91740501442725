import React, { useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import iconUrl from 'leaflet/dist/images/marker-icon.png';
import iconRetinaUrl from 'leaflet/dist/images/marker-icon-2x.png';
import shadowUrl from 'leaflet/dist/images/marker-shadow.png';

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl,
    iconUrl,
    shadowUrl,
});

const Map = ({ markers }) => {
    const defaultCenter = [21.0285, 105.8542];

    const MapEffect = ({ center }) => {
        const map = useMap();
        useEffect(() => {
            if (center) {
                map.setView(center);
            }
        }, [center, map]);
        return null;
    };

    const center = markers.length > 0 ? markers[0].position : defaultCenter;

    return (
        <MapContainer center={center} zoom={13} style={{ height: '500px', width: '100%', zIndex: '0' }}>
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <MapEffect center={center} />
            {markers.map((marker, idx) => (
                <Marker key={idx} position={marker.position}>
                    <Popup>{marker.popupText}</Popup>
                </Marker>
            ))}
        </MapContainer>
    );
};

export default Map;
