import React, { useEffect, useState } from 'react'
import { Sitem } from './index'
import { useDispatch, useSelector } from 'react-redux'
import * as actions from '../store/actions'

const RelatedPost = ({ newPost }) => {
    const { newPosts, outStandingPost } = useSelector(state => state.post);
    const [posts, setPosts] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        setPosts([]);  // Clear the posts before fetching new data
        if (newPost) {
            dispatch(actions.getNewPosts());
        } else {
            dispatch(actions.getOutStandingPosts());
        }
    }, [newPost, dispatch]);

    useEffect(() => {
        let fetchedPosts = newPost ? newPosts : outStandingPost;

        if (Array.isArray(fetchedPosts)) {
            const uniquePosts = fetchedPosts.filter((post, index, self) =>
                index === self.findIndex(p => p.id === post.id)
            );
            setPosts(uniquePosts.slice(0, 5));
        } else {
            console.error('Fetched posts are not in the expected array format:', fetchedPosts);
            setPosts([]);
        }
    }, [newPost, newPosts, outStandingPost]);

    const renderImages = (item) => {
        let images = [];

        if (item?.images?.image) {
            if (typeof item.images.image === 'string') {
                // Handle image URL strings
                images = item.images.image.split(',');
            } else if (Array.isArray(item.images.image)) {
                // Handle array of image URLs
                images = item.images.image;
            } else if (item.images.image.type === 'Buffer' && Array.isArray(item.images.image.data)) {
                // Handle Buffer data
                const base64String = btoa(String.fromCharCode(...item.images.image.data));
                images.push(`data:image/jpg;base64,${base64String}`);
            } else {
                console.error("Unexpected image format:", item.images.image);
            }
        }

      

        return images;
    };

    return (
        <div className="w-full bg-white rounded-md p-4">
            <h3 className="font-semibold text-lg mb-4">{newPost ? 'Tin mới đăng' : 'Tin nổi bật'}</h3>
            <div className="w-full flex flex-col gap-2">
                {posts && posts.length > 0 && posts.slice(0, 5).map(item => {
                    const images = renderImages(item);

                    return (
                        <Sitem
                            key={item.id}
                            title={item.title}
                            price={item?.attributes?.price}
                            createdAt={item.createdAt}
                            images={images}
                            star={item?.star}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default RelatedPost;