import React, { useEffect, useState } from "react";
import { createSearchParams, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getPostsLimit } from "../../store/actions";
import Slider from 'react-slick';
import { UserInfor, RelatedPost } from "../../components";
import icons from "../../ultils/icons";
import { useNavigate } from "react-router-dom";
import { path } from "../../ultils/constant";
import Comment from "./Comment";
import { updatePostLikeStatus } from "../../store/actions/post";
import { clearPosts } from "../../store/actions/post";
import axios from "axios";
import Map from "./Map";
import ReactPlayer from 'react-player';

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};
const { FaLocationDot, TbReportMoney, RiCrop2Line, CiHashtag, CiStopwatch } = icons;

const DetailPost = () => {
  const { postId } = useParams();
  const dispatch = useDispatch();
  const { posts } = useSelector((state) => state.post);
  const navigate = useNavigate();
  const { currentData } = useSelector((state) => state.user);
  const { isLoggedIn } = useSelector((state) => state.auth);
  const [markers, setMarkers] = useState([]);
  const [media, setMedia] = useState([]);

  useEffect(() => {
    if (postId) {
      dispatch(getPostsLimit({ id: postId }));
    }

    return () => {
      dispatch(clearPosts());
    };
  }, [postId, dispatch]);

  useEffect(() => {
    console.log('Posts:', posts); // Log posts to check if they have data

    if (posts.length > 0) {
      const post = posts[0];
      console.log('Post:', post); // Log the post to check if it contains coordinate and address

      const { coordinate, address } = post;

      if (coordinate && address) {
        // Split coordinate into lat and lon and convert them to numbers
        const [lat, lon] = coordinate.split(',').map(Number);

        if (!isNaN(lat) && !isNaN(lon)) {
          const newMarkers = [{ position: [lat, lon], popupText: address.replace(/^Địa chỉ:\s*/, '') }];
          setMarkers(newMarkers);

          console.log('Markers:', newMarkers);
        } else {
          console.log('Invalid coordinate values');
        }
      } else {
        console.log('Coordinate or address not found in post:', post);
      }
    } else {
      console.log('No posts available');
    }
  }, [posts]);




  useEffect(() => {
    const fetchMedia = async () => {
      try {
        if (posts.length > 0) {
          const imagesData = posts[0]?.images?.image || '';
          const videosData = posts[0]?.videos?.video || '';

          const imagesArray = imagesData ? imagesData.split(',') : [];
          const videosArray = videosData ? videosData.split(',') : [];

          const combinedMedia = [...imagesArray, ...videosArray];

          setMedia(combinedMedia);
        }
      } catch (error) {
        console.error('Error fetching media:', error);
        setMedia([]);
      }
    };

    fetchMedia();
  }, [posts]);

  const handleFilterLabel = () => {
    const labelCode = posts[0]?.labelData?.code;
    const titleSearch = `Tìm kiếm tin đăng theo chuyên mục ${posts[0]?.labelData?.value}`;
    navigate(
      {
        pathname: `/search`,
        search: createSearchParams({ labelCode }).toString(),
      },
      { state: { titleSearch } }
    );
  };

  const handleLikeToggle = (postId, isLiked) => {
    dispatch(updatePostLikeStatus(postId, !isLiked));
  };

  return (
    <div className="w-full flex flex gap-2" style={isLoggedIn ? { marginTop: '96px' } : {}}>
      <div className="w-[70%]">
        <div className='w-full'>
          {media.length > 0 ? (
            <Slider {...settings}>
              {media.map((item, index) => (
                <div key={index} className='bg-black flex justify-center h-[320px] px-12'>
                  {item.endsWith('.mp4') ? (
                    <video
                      src={`${item}?cacheBuster=${new Date().getTime()}`}
                      controls
                      className='object-contain m-auto h-full'
                    />
                  ) : item ? (
                    <img
                      src={item}
                      alt='slider'
                      className='object-contain m-auto h-full'
                    />
                  ) : (
                    <img
                      src='/path/to/placeholder-image.jpg'
                      alt='placeholder'
                      className='object-contain m-auto h-full'
                    />
                  )}
                </div>
              ))}
            </Slider>
          ) : (
            <p>No media available</p>
          )}
        </div>
        <div className="bg-white rounded-md shadow-md p-4">
          <div className="flex flex-col gap-2">
            <h2 className="text-xl font-bold text-red-600">
              {posts[0]?.title}
            </h2>
            <div className="flex items-center gap-2">
              <span>Chuyên mục:</span>
              <span
                onClick={handleFilterLabel}
                className="text-blue-600 font-medium hover:text-orange-500 cursor-pointer"
              >
                {posts[0]?.labelData?.value}
              </span>
            </div>
            <div className="flex gap-2 items-center">
              <FaLocationDot color="red" />
              <span>{posts[0]?.address}</span>
            </div>
            <div className={`menu-1 flex items-center justify-between`}>
              <span className="flex items-center gap-1">
                <TbReportMoney />
                <span className="font-medium text-lg text-green-500">{posts[0]?.attributes?.price}</span>
              </span>
              <span className="flex items-center gap-1">
                <RiCrop2Line />
                <span>{posts[0]?.attributes?.acreage}</span>
              </span>
              <span className="flex items-center gap-1">
                <CiStopwatch />
                <span>{posts[0]?.attributes?.published}</span>
              </span>
              <span className="flex items-center gap-1">
                <CiHashtag />
                <span>{posts[0]?.attributes?.hashtag}</span>
              </span>
            </div>
          </div>
          <div className="mt-8">
            <h3 className="font-semibold text-xl my-4">Thông tin mô tả:</h3>
            <div className="flex flex-col gap-2">
              {posts.length > 0 && (
                Array.isArray(posts[0]?.description)
                  ? posts[0]?.description.map((item, index) => (
                    <span key={index}>{item.replace(/"/g, '')}</span>
                  ))
                  : <span>{posts[0]?.description.replace(/"/g, '')}</span>
              )}
            </div>
          </div>
          <div className="mt-8">
            <h3 className="font-semibold text-xl my-4">Đặc điểm tin đăng:</h3>
            <table>
              <tbody>
                <tr className="w-full flex">
                  <td className="p-2 w-[150px] flex-none">Mã tin:</td>
                  <td className="p-2 flex-auto">{posts[0]?.overviews?.code}</td>
                </tr>
                <tr className="w-full flex bg-zinc-200">
                  <td className="p-2 w-[150px] flex-none">Khu vực:</td>
                  <td className="p-2">{posts[0]?.overviews?.area}</td>
                </tr>
                <tr className="w-full flex">
                  <td className="p-2 w-[150px] flex-none">Loại tin rao:</td>
                  <td className="p-2">{posts[0]?.overviews?.type}</td>
                </tr>
                <tr className="w-full flex bg-zinc-200">
                  <td className="p-2 w-[150px] flex-none">Đối tượng:</td>
                  <td className="p-2">{posts[0]?.overviews?.target}</td>
                </tr>
                <tr className="w-full flex">
                  <td className="p-2 w-[150px] flex-none">Gói tin:</td>
                  <td className="p-2">{posts[0]?.overviews?.bonus}</td>
                </tr>
                <tr className="w-full flex bg-zinc-200">
                  <td className="p-2 w-[150px] flex-none">Ngày đăng:</td>
                  <td className="p-2">{posts[0]?.overviews?.created}</td>
                </tr>
                <tr className="w-full flex">
                  <td className="p-2 w-[150px] flex-none">Ngày hết hạn:</td>
                  <td className="p-2">{posts[0]?.overviews?.expired}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="mt-8">
            <h3 className="font-semibold text-xl my-4">Thông tin liên hệ:</h3>
            <table>
              <tbody>
                <tr className="w-full flex">
                  <td className="p-2 w-[150px] flex-none">Liên hệ:</td>
                  <td className="p-2 flex-auto">{posts[0]?.user?.name}</td>
                </tr>
                <tr className="w-full flex bg-zinc-200">
                  <td className="p-2 w-[150px] flex-none">Điện thoại:</td>
                  <td className="p-2">{posts[0]?.user?.phone}</td>
                </tr>
                <tr className="w-full flex">
                  <td className="p-2 w-[150px] flex-none">Zalo:</td>
                  <td className="p-2">{posts[0]?.user?.zalo}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <Map center={markers[0]?.position || [0, 0]} markers={markers} />

      </div>
      <div className="w-[30%] flex flex-col gap-6">
        <UserInfor userData={posts[0]?.user} onLikeToggle={handleLikeToggle} userId={currentData.id} />
        <RelatedPost />
        <RelatedPost newPost />
      </div>
    </div>
  );
};

export default DetailPost;  