import React from 'react'
import { Navigation } from '../public'
import { NavLink } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { Button } from 'antd';
import { AiOutlineMenu } from 'react-icons/ai';
const Header = ({ onMenuClick }) => {
    const isMdOrLower = useMediaQuery({ query: '(max-width: 768px)' });

    return (
        <div className='w-full flex flex-none h-[40px]'>
            <div className={`flex justify-between items-center font-bold bg-secondary1 text-white ${isMdOrLower ? 'w-full':'w-[256px]'}  flex-none`}>
                <NavLink to={`/`} className="logo text-md font-bold">
                    THAOLOANLAND.COM
                </NavLink>
                {isMdOrLower && <Button  icon={<AiOutlineMenu />} onClick={onMenuClick} />}
            </div>
            <div className='flex-auto flex justify-between items-center'>
                
                <Navigation isAdmin={true} />
            </div>
        </div>
    );
};


export default Header