import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '../../components'
import { fetchUsers, updateUser, deleteUser } from '../../store/actions/user.js';
const formatDate = 'DD/MM/YYYY'
const ManagerUser = () => {
    const dispatch = useDispatch();
    const { users = [], loading, error } = useSelector((state) => state.user);

    useEffect(() => {
        dispatch(fetchUsers());
    }, [dispatch]);

    const handleUpdateUser = (id, data) => {
        dispatch(updateUser(id, data));
    };

    const handleDeleteUser = (id) => {
        dispatch(deleteUser(id));
    };

    return (
        <div className='flex flex-col gap-6'>
            <div className='py-4 border-b border-gray-200 flex items-center justify-between'>
                <h1 className='text-3xl font-medium'>Quản lý người dùng</h1>
            </div>
            {loading ? (
                <p>Loading...</p>
            ) : error ? (
                <p>Error: {JSON.stringify(error)}</p>
            ) : users.length > 0 ? (
                <table className="w-full table-auto mb-4">
                    <thead>
                        <tr className='bg-gray-100'>
                            <th className='border p-2'>Họ tên</th>
                            <th className='border p-2'>Số điện thoại</th>
                            <th className='border p-2'>Quản trị viên</th>
                            <th className='border p-2'>Tùy chọn</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map((user) => (
                            <UserItem
                                key={user.id}
                                user={user}
                                onUpdate={handleUpdateUser}
                                onDelete={handleDeleteUser}
                            />
                        ))}
                    </tbody>
                </table>
            ) : (
                <p>No user found</p>
            )}
        </div>
    );
};

const UserItem = ({ user, onUpdate, onDelete }) => {
    const [editMode, setEditMode] = useState(false);
    const [userData, setUserData] = useState({ ...user });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUserData({ ...userData, [name]: value });
    };

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setUserData({ ...userData, [name]: checked });
    };

    return (
        <tr className="h-16">
            {editMode ? (
                <>
                    <td className="border px-2 h-full">
                        <input
                            type="text"
                            name="name"
                            className='border-2 text-center w-full'
                            value={userData.name}
                            onChange={handleInputChange}
                        />
                    </td>
                    <td className="border px-2 h-full">
                        <input
                            type="text"
                            name="phone"
                            className='border-2 text-center w-full'
                            value={userData.phone}
                            onChange={handleInputChange}
                        />
                    </td>
                    <td className="border px-2 h-full ">
                        <input
                            type="checkbox"
                            name="isAdmin"
                            className='border-2'
                            checked={userData.isAdmin}
                            onChange={handleCheckboxChange}
                        />
                    </td>
                </>
            ) : (
                <>
                    <td className="border px-2 h-full">
                        <p className='text-center'>{user.name}</p>
                    </td>
                    <td className="border px-2 h-full">
                        <p className='text-center'>{user.phone}</p>
                    </td>
                    <td className="border px-2 h-full">
                        <p className='text-center'>{user.isAdmin ? 'Yes' : 'No'}</p>
                    </td>
                </>
            )}
            <td className="border px-2 h-full ">
                {editMode ? (
                    <div className='flex items-center justify-center'>
                        <Button
                            bgColor='bg-blue-600'
                            text='Lưu'
                            textColor='text-white'
                            onClick={() => {
                                onUpdate(user.id, userData);
                                setEditMode(false);
                            }}
                        />
                    </div>
                ) : (
                    <>
                        <div className='flex justify-center items-center flex items-center justify-center gap-4'>
                            <Button
                                text='Sửa'
                                bgColor='bg-green-600'
                                textColor='text-white'
                                onClick={() => setEditMode(true)}
                            />
                            <Button
                                text='Xóa'
                                bgColor='bg-red-600'
                                textColor='text-white'
                                onClick={() => onDelete(user.id)}
                            />
                        </div>
                    </>
                )}
            </td>
        </tr>
    );
};

export default ManagerUser