import React, { useEffect, useState } from 'react'
import { PageNumber } from '../../components'
import { useSelector } from 'react-redux'
import icons from '../../ultils/icons'
import { useSearchParams } from 'react-router-dom'
import { usePagination } from './PaginationProvider'
const { GrLinkNext } = icons

const Pagination = () => {
    const { favoritePostsCount } = usePagination();
    const { posts } = useSelector(state => state.post);
    const [arrPage, setArrPage] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [isHideEnd, setIsHideEnd] = useState(false);
    const [isHideStart, setIsHideStart] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        let page = searchParams.get('page');
        page && +page !== currentPage && setCurrentPage(+page);
        !page && setCurrentPage(1);
    }, [searchParams, currentPage]);

    useEffect(() => {
        const approvedPosts = posts.filter(post => post.status === 'approved');
        const uniqueApprovedPosts = Array.from(new Set(approvedPosts.map(post => post.id)))
            .map(id => approvedPosts.find(post => post.id === id));
        const approvedPostsCount = favoritePostsCount > 0
            ? favoritePostsCount
            : uniqueApprovedPosts.length;

        let maxPage = Math.ceil(approvedPostsCount / 6);
        let end = (currentPage + 2) > maxPage ? maxPage : (currentPage + 2);
        let start = (currentPage - 2) <= 1 ? 1 : (currentPage - 2);
        let temp = [];
        for (let i = start; i <= end; i++) temp.push(i);
        setArrPage(temp);
        setIsHideEnd(currentPage >= (maxPage - 2));
        setIsHideStart(currentPage <= 3);
    }, [favoritePostsCount, posts, currentPage]);

    const approvedPosts = posts.filter(post => post.status === 'approved');
    const uniqueApprovedPosts = Array.from(new Set(approvedPosts.map(post => post.id)))
        .map(id => approvedPosts.find(post => post.id === id));

    const handleNextPage = () => {
        const approvedPostsCount = favoritePostsCount > 0
            ? favoritePostsCount
            : uniqueApprovedPosts.length;

        let maxPage = Math.ceil(approvedPostsCount / 6);
        if (currentPage < maxPage) {
            setCurrentPage(prev => prev + 1);
            setSearchParams({ page: currentPage + 1 }); // Update URL params
        }
    };

    return (
        <div className='flex items-center justify-center gap-2 py-5'>
            {!isHideStart && <PageNumber setCurrentPage={setCurrentPage} text={1} />}
            {(!isHideStart && currentPage !== 4) && <PageNumber text={'...'} />}
            {arrPage.length > 0 && arrPage.map(item => (
                <PageNumber
                    key={item}
                    text={item}
                    setCurrentPage={page => {
                        setCurrentPage(page);
                        setSearchParams({ page }); 
                    }}
                    currentPage={currentPage}
                />
            ))}
            {!isHideEnd && <PageNumber text={'...'} />}
            {!isHideEnd && currentPage < Math.ceil(favoritePostsCount > 0 ? favoritePostsCount : uniqueApprovedPosts.length / 6) && (
                <PageNumber icon={<GrLinkNext />} setCurrentPage={handleNextPage} text={currentPage + 1} />
            )}
        </div>
    );
};

export default Pagination