import React, { useCallback, useEffect, useState } from 'react'
import { SearchItem, Modal } from '../../components'
import icons from '../../ultils/icons'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, createSearchParams, useLocation } from 'react-router-dom'
import { path } from '../../ultils/constant'
import { getCodes, getCodesArea } from '../../ultils/Common/getCodes'
const { BsChevronRight, HiOutlineLocationMarker, TbReportMoney, RiCrop2Line, MdOutlineHouseSiding, FiSearch } = icons

const Search = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [isShowModal, setIsShowModal] = useState(false);
    const [content, setContent] = useState([]);
    const [name, setName] = useState('');
    const [selectedProvince, setSelectedProvince] = useState(null);
    const { provinces, areas, prices, categories, districts } = useSelector(state => state.app);
    const [queries, setQueries] = useState({});
    const [arrMinMax, setArrMinMax] = useState({});
    const [defaultText, setDefaultText] = useState('');
    const { isLoggedIn } = useSelector(state => state.auth);

    useEffect(() => {
        if (!location.pathname.includes(path.SEARCH)) {
            setArrMinMax({});
            setQueries({});
        }
    }, [location]);

    const handleShowModal = (content, name, defaultText) => {
        setContent(content);
        setName(name);
        setDefaultText(defaultText);
        setIsShowModal(true);
    };

    const handleSubmit = useCallback((e, query, arrMaxMin) => {
        e.stopPropagation();
        if (name === 'province') {
            const selectedProvince = content.find(p => p.code === query.provinceCode);
            setSelectedProvince(selectedProvince);
            setQueries(prev => ({
                ...prev,
                province: selectedProvince?.value,
                provinceCode: selectedProvince?.code,
                district: '',  // Reset district when a new province is selected
                districtCode: ''
            }));
            setIsShowModal(false);
        } else if (name === 'district') {
            setQueries(prev => ({
                ...prev,
                district: query.district,
                districtCode: query.districtCode
            }));
            setIsShowModal(false);
        } else {
            setQueries(prev => ({ ...prev, ...query }));
            setIsShowModal(false);
            arrMaxMin && setArrMinMax(prev => ({ ...prev, ...arrMaxMin }));
        }
    }, [isShowModal, queries, content, name]);

    useEffect(() => {
        if (selectedProvince) {
            const filteredDistricts = districts.filter(d => d.provinceCode === selectedProvince.code);
            handleShowModal(filteredDistricts, 'district', 'Chọn quận/huyện');
        }
    }, [selectedProvince, districts]);

    const handleSearch = () => {
        const queryCodes = Object.entries(queries)
            .filter(item => item[0].includes('Number') || item[0].includes('Code'))
            .filter(item => item[1]);
        let queryCodesObj = {};
        queryCodes.forEach(item => { queryCodesObj[item[0]] = item[1]; });

        const queryText = Object.entries(queries)
            .filter(item => !item[0].includes('Code') && !item[0].includes('Number'));

        let queryTextObj = {};
        queryText.forEach(item => { queryTextObj[item[0]] = item[1] });

        let titleSearch = `${queryTextObj.category
            ? queryTextObj.category
            : 'Cho thuê tất cả'} ${queryTextObj.province
                ? `tỉnh ${queryTextObj.province}`
                : ''} ${queryTextObj.price
                    ? `giá ${queryTextObj.price}`
                    : ''} ${queryTextObj.area
                        ? `diện tích ${queryTextObj.area}` : ''} `;

        navigate({
            pathname: path.SEARCH,
            search: createSearchParams(queryCodesObj).toString(),
        }, { state: { titleSearch } });
    };
    // Check if the current path is /login
    if (location.pathname === '/login') {
        return null;
    }
    return (
        <>
            <div style={isLoggedIn ? { marginTop: '96px' } : {}} className='p-[10px] w-full my-3 bg-[#febb02] rounded-lg flex-col lg:flex-row flex items-center justify-around gap-2'>
                <span onClick={() => handleShowModal(categories, 'category', 'Tìm tất cả')} className='cursor-pointer flex-1'>
                    <SearchItem IconBefore={<MdOutlineHouseSiding />} fontWeight IconAfter={<BsChevronRight color='rgb(156, 163, 175)' />} text={queries.category} defaultText={'Tìm tất cả'} />
                </span>
                <span onClick={() => handleShowModal(provinces, 'province', 'Toàn quốc')} className='cursor-pointer flex-1'>
                    <SearchItem IconBefore={<HiOutlineLocationMarker />} IconAfter={<BsChevronRight color='rgb(156, 163, 175)' />} text={`${queries.province || ''}${queries.district ? ', ' + queries.district : ''}`} defaultText={'Toàn quốc'} />
                </span>
                <span onClick={() => handleShowModal(prices, 'price', 'Chọn giá')} className='cursor-pointer flex-1'>
                    <SearchItem IconBefore={<TbReportMoney />} IconAfter={<BsChevronRight color='rgb(156, 163, 175)' />} text={queries.price} defaultText={'Chọn giá'} />
                </span>
                <span onClick={() => handleShowModal(areas, 'area', 'Chọn diện tích')} className='cursor-pointer flex-1'>
                    <SearchItem IconBefore={<RiCrop2Line />} IconAfter={<BsChevronRight color='rgb(156, 163, 175)' />} text={queries.area} defaultText={'Chọn diện tích'} />
                </span>
                <button
                    type='button'
                    onClick={handleSearch}
                    className='outline-none py-2 h-[50px] rounded-md px-4 flex-1 bg-secondary1 text-white font-medium flex items-center justify-center gap-2'
                >
                    <FiSearch />
                    Tìm kiếm
                </button>
            </div>
            {isShowModal && (
                <Modal
                    handleSubmit={handleSubmit}
                    arrMinMax={arrMinMax}
                    name={name}
                    content={content}
                    queries={queries}
                    setIsShowModal={setIsShowModal}
                    defaultText={defaultText}
                />
            )}
        </>
    );
};

export default Search