import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { formatVietnameseToString } from '../../ultils/Common/formatVietnameseToString'
import { useDispatch, useSelector } from 'react-redux'
import * as actions from '../../store/actions'
import { path } from '../../ultils/constant'

const notActive = 'hover:bg-secondary2 h-full flex items-center bg-secondary1 mx-2 px-1';
const active = 'hover:bg-secondary2 h-full flex items-center bg-secondary2 mx-2 px-1';

const desiredOrder = [
    'Thuê phòng trọ',
    'Thuê nhà nguyên căn',
    'CHDV - CC',
    'Mặt bằng , văn phòng',
    '+1000 GROUP Facebook'
];

const Navigation = ({ isAdmin }) => {
    const dispatch = useDispatch();
    const { categories } = useSelector(state => state.app);

    useEffect(() => {
        dispatch(actions.getCategories());
    }, [dispatch]);

    const sortedCategories = categories?.slice().sort((a, b) => {
        return desiredOrder.indexOf(a.value) - desiredOrder.indexOf(b.value);
    });

    return (
        <div className={` displayNone w-full flex ${isAdmin ? 'justify-start' : 'justify-center'} items-center h-[40px] bg-secondary1 text-white`}>
            <div className='w-full flex h-full items-center text-sm font-medium justify-between'>
                <NavLink
                    to={`/`}
                    className={({ isActive }) => isActive ? active : notActive}
                >
                    Trang chủ
                </NavLink>
                {sortedCategories?.length > 0 && sortedCategories.map(item => (
                    <div key={item.code} className='h-full flex justify-center items-center'>
                        <NavLink
                            to={`/${formatVietnameseToString(item.value)}`}
                            className={({ isActive }) => isActive ? active : notActive}
                        >
                            {item.value}
                        </NavLink>
                    </div>
                ))}
            </div>
        </div>
    );
};
export default Navigation