import axios from "axios";

const isProduction = process.env.NODE_ENV === 'production';
const API_URL = isProduction ? process.env.REACT_APP_PROD_SERVER_URL : process.env.REACT_APP_SERVER_URL;

const instance = axios.create({
  baseURL: API_URL.replace("http://", "https://")
  // .replace("http://", "https://")
});

// Add a request interceptor
instance.interceptors.request.use(function (config) {
  // Do something before request is sent
  // Attach token to header
  let token = window.localStorage.getItem('persist:auth') && JSON.parse(window.localStorage.getItem('persist:auth'))?.token?.slice(1, -1);
  config.headers = {
    authorization: token ? `Bearer ${token}` : null
  }
  return config;
}, function (error) {
  return Promise.reject(error);
});

// Add a response interceptor
instance.interceptors.response.use(function (response) {
  // Refresh token
  return response;
}, function (error) {
  return Promise.reject(error);
});

export default instance;
