import React, { memo } from 'react'
import moment from 'moment'
import icons from '../ultils/icons'

import 'moment/locale/vi'


const { GrStar } = icons


const Sitem = ({ title, price, images, createdAt, star }) => {

    const formatTime = (createdAt) => {
        return moment(createdAt).fromNow();
    };

    const handleStar = (star) => {
        let stars = [];
        for (let i = 1; i <= +star; i++) stars.push(<GrStar className='star-item' size={18} color='yellow' />);
        return stars;
    };

    return (
        <div className='menu-1 w-full flex flex-col sm:flex-row items-center gap-2 py-2 border-b border-gray-300'>
            {images && images.length > 0 ? (
                <img
                    src={images[0]}
                    alt="anh"
                    className='w-[65px] h-[65px] object-cover flex-none rounded-md'
                />
            ) : (
                <div className='w-[65px] h-[65px] flex-none rounded-md bg-gray-200 flex items-center justify-center'>
                    <span>No Image</span>
                </div>
            )}
            <div className='w-full flex-auto flex flex-col justify-between gap-1'>
                <h4 className='text-blue-600 text-[14px]'>
                    {handleStar(+star).length > 0 && handleStar(+star).map((star, number) => (
                        <span key={number}>{star}</span>
                    ))}
                    {`${title?.slice(0, 45)}...`}
                </h4>
                <div className='flex flex-col sm:flex-row items-start sm:items-center justify-between w-full'>
                    <span className='text-sm font-medium text-green-500'>{price}</span>
                    <span className='text-sm text-gray-300'>{formatTime(createdAt)}</span>
                </div>
            </div>
        </div>
    );
};

export default memo(Sitem)