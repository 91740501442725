import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, UpdatePost } from '../../components'
import { getPosts, updatePost, deletePost } from '../../store/actions';


const formatDate = 'DD/MM/YYYY'
const ManagerPost = () => {
    const dispatch = useDispatch();
    const { posts = [], loading, error } = useSelector(state => state.post);
    const { currentData } = useSelector((state) => state.user);
    const userId = currentData?.id;
    const isAdmin = currentData?.isAdmin === 1;

    useEffect(() => {
        dispatch(getPosts());
    }, [dispatch]);

    const handleUpdatePost = (id, data) => {
        dispatch(updatePost(data, id)).then(() => {
            dispatch(getPosts()); // Refresh the post list after update
        });
    };

    const handleDeletePost = (id) => {
        dispatch(deletePost(id)).then(() => {
            dispatch(getPosts()); // Refresh the post list after deletion
        });
    };

    const filteredPosts = isAdmin ? posts : posts.filter(post => post.user.id === userId);

    return (
        <div className='flex flex-col gap-6'>
            <div className='py-4 border-b border-gray-200 flex items-center justify-between'>
                <h1 className='text-3xl font-medium '>Quản lý tin đăng</h1>
            </div>
            {loading ? (
                <p>Loading...</p>
            ) : error ? (
                <p>Error: {JSON.stringify(error)}</p>
            ) : filteredPosts.length > 0 ? (
                <table className="w-full table-auto mb-4">
                    <thead>
                        <tr className='bg-gray-100'>
                            <th className='border p-2'>Mã tin</th>
                            <th className='border p-2'>Tiêu đề</th>
                            <th className='border p-2'>Mô tả</th>
                            <th className='border p-2'>Giá</th>
                            <th className='border p-2'>Ngày bắt đầu</th>
                            <th className='border p-2'>Ngày hết hạn</th>
                            <th className='border p-2'>Trạng thái</th>
                            <th className='border p-2'>Tùy chọn</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredPosts.map((post) => (
                            <PostItem
                                key={post.id}
                                post={post}
                                onUpdate={handleUpdatePost}
                                onDelete={handleDeletePost}
                                isAdmin={isAdmin}
                            />
                        ))}
                    </tbody>
                </table>
            ) : (
                <h3 className='font-bold text-[16px]'>Chưa có bài đăng nào</h3>
            )}
        </div>
    );
};

const PostItem = ({ post, onUpdate, onDelete, isAdmin }) => {
    const [editMode, setEditMode] = useState(false);
    const [postData, setPostData] = useState({
        ...post,
        images: Array.isArray(post.images?.image)
            ? post.images.image
            : typeof post.images?.image === 'string'
            ? post.images.image.split(',').map(item => item.trim())
            : [],
        videos: Array.isArray(post.videos?.video)
            ? post.videos.video
            : typeof post.videos?.video === 'string'
            ? post.videos.video.split(',').map(item => item.trim())
            : [],
    });

    const sanitizeInput = (value) => {
        return value.replace(/^"|"$/g, '').replace(/\\"/g, '"');
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        const sanitizedValue = sanitizeInput(value);

        if (['code', 'area', 'type', 'target', 'bonus', 'created', 'expired'].includes(name)) {
            setPostData((prevState) => ({
                ...prevState,
                overviews: {
                    ...prevState.overviews,
                    [name]: sanitizedValue,
                },
            }));
        } else if (name === 'price') {
            setPostData((prevState) => ({
                ...prevState,
                attributes: {
                    ...prevState.attributes,
                    [name]: sanitizedValue,
                },
            }));
        } else if (name === 'images') {
            setPostData((prevState) => ({
                ...prevState,
                images: sanitizedValue.split(',').map(item => item.trim()),
            }));
        } else if (name === 'videos') {
            setPostData((prevState) => ({
                ...prevState,
                videos: sanitizedValue.split(',').map(item => item.trim()),
            }));
        } else {
            setPostData((prevState) => ({
                ...prevState,
                [name]: sanitizedValue,
            }));
        }
    };

    const handleApprove = () => {
        setPostData((prevState) => ({
            ...prevState,
            status: 'approved',
        }));
        onUpdate(post.id, { ...postData, status: 'approved' });
    };

    const handleReject = () => {
        setPostData((prevState) => ({
            ...prevState,
            status: 'rejected',
        }));
        onUpdate(post.id, { ...postData, status: 'rejected' });
    };

    const handleSave = () => {
        const updatedPostData = {
            ...postData,
            images: { image: postData.images.join(',') },
            videos: { video: postData.videos.join(',') },
            status: postData.status,
        };
        onUpdate(post.id, updatedPostData);
        setEditMode(false);
    };

    const getStatusText = (status) => {
        switch (status) {
            case 'approved':
                return 'Đã phê duyệt';
            case 'rejected':
                return 'Từ chối';
            case 'pending':
            default:
                return 'Đang duyệt';
        }
    };

    return (
        <tr className="h-16">
            {editMode ? (
                <>
                    <td className="border px-2 h-full">
                        <input
                            type="text"
                            name="code"
                            className='border-2 text-center w-full'
                            value={postData.overviews.code}
                            onChange={handleInputChange}
                        />
                    </td>
                    <td className="border px-2 h-full">
                        <input
                            type="text"
                            name="title"
                            value={postData.title}
                            className='border-2 text-center w-full'
                            onChange={handleInputChange}
                        />
                    </td>
                    <td className="border px-2 h-full">
                        <input
                            type="text"
                            name="description"
                            value={postData.description}
                            className='border-2 text-center w-full'
                            onChange={handleInputChange}
                        />
                    </td>
                    <td className="border px-2 h-full">
                        <input
                            type="text"
                            name="price"
                            className='border-2 text-center w-full'
                            value={postData.attributes.price}
                            onChange={handleInputChange}
                        />
                    </td>
                    <td className="border px-2 h-full">
                        <input
                            type="text"
                            name="created"
                            className='border-2 text-center w-full'
                            value={postData.overviews.created}
                            onChange={handleInputChange}
                        />
                    </td>
                    <td className="border px-2 h-full">
                        <input
                            type="text"
                            name="expired"
                            className='border-2 text-center w-full'
                            value={postData.overviews.expired}
                            onChange={handleInputChange}
                        />
                    </td>
                    <td className="border px-2 h-full text-center">
                        {isAdmin ? (
                            <>
                                <button
                                    className={`px-4 py-2 rounded-full ${postData.status === 'approved' ? 'bg-green-600 text-white' : 'bg-gray-200 text-black'}`}
                                    onClick={handleApprove}
                                >
                                    Phê duyệt
                                </button>
                                <button
                                    className={`px-4 py-2 rounded-full ml-2 ${postData.status === 'rejected' ? 'bg-red-600 text-white' : 'bg-gray-200 text-black'}`}
                                    onClick={handleReject}
                                >
                                    Từ chối
                                </button>
                            </>
                        ) : (
                            <p>{getStatusText(post.status)}</p>
                        )}
                    </td>
                </>
            ) : (
                <>
                    <td className="border px-2 h-full">
                        <p className='text-center'>{post.overviews.code}</p>
                    </td>
                    <td className="border px-2 h-full">
                        <p className='truncate text-center'>{post.title}</p>
                    </td>
                    <td className="border px-2 h-full">
                        <p className='truncate text-center'>{post.description}</p>
                    </td>
                    <td className="border px-2 h-full">
                        <p className='text-center'>{post.attributes.price}</p>
                    </td>
                    <td className="border px-2 h-full">
                        <p className='text-center'>{post.overviews.created}</p>
                    </td>
                    <td className="border px-2 h-full">
                        <p className='text-center'>{post.overviews.expired}</p>
                    </td>
                    <td className="border px-2 h-full text-center">
                        <p>{getStatusText(post.status)}</p>
                        {isAdmin && post.status === 'pending' && (
                            <>
                                <Button
                                    text='Phê duyệt'
                                    bgColor='bg-green-600'
                                    textColor='text-white'
                                    onClick={handleApprove}
                                />
                                <Button
                                    text='Từ chối'
                                    bgColor='bg-red-600'
                                    textColor='text-white'
                                    onClick={handleReject}
                                />
                            </>
                        )}
                    </td>
                </>
            )}
            <td className="border px-2 h-full">
                {editMode ? (
                    <div className='flex items-center justify-center'>
                        <Button
                            bgColor='bg-blue-600'
                            text='Lưu'
                            textColor='text-white'
                            onClick={handleSave}
                        />
                    </div>
                ) : (
                    <div className='flex items-center justify-center gap-4'>
                        <Button
                            text='Sửa'
                            bgColor='bg-green-600'
                            textColor='text-white'
                            onClick={() => setEditMode(true)}
                        />
                        <Button
                            text='Xóa'
                            bgColor='bg-red-600'
                            textColor='text-white'
                            onClick={() => onDelete(post.id)}
                        />
                    </div>
                )}
            </td>
        </tr>
    );
};
export default ManagerPost 