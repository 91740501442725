import React, { useEffect, useRef } from 'react'
import Header from './Header'
import { Outlet, useLocation } from 'react-router-dom'
import { Footer, Navigation, Search } from './index.js'
import { Intro, Contact } from '../../components'
import { useSelector } from 'react-redux'
import { path } from '../../ultils/constant'
import { useNavigate } from 'react-router-dom';

const Home = () => {
    const { isLoggedIn } = useSelector(state => state.auth);
    const location = useLocation();
    const navigate = useNavigate();

    const { currentData } = useSelector((state) => state.user);
    const userId = currentData?.id;
    const isAdmin = currentData?.isAdmin === 1;
    
    const shouldShowSearch = !(location.pathname === `/${path.CONTACT}` || location.pathname.includes(path.DETAIL));
    const lgScreen = window.innerWidth < 1130;

    const handleQuetViTri = () => {
        navigate('/quet-vi-tri');
    };

    // Check if the current page is the Home page and the user is an admin
    const shouldShowQuetViTriButton = location.pathname === '/' && isAdmin;

    return (
        <div className='w-full flex gap-5 flex-col items-center h-full'>
            <Header />

            <div className={`marginTop8px mt-[64px] w-full p-4 flex flex-col items-start justify-start `}>
                {shouldShowSearch && <Search isShow={shouldShowSearch} />}
                {shouldShowQuetViTriButton && (
                    <button onClick={handleQuetViTri} className="mt-4 px-4 py-2 bg-blue-600 text-white rounded">
                        Quét vị trí
                    </button>
                )}
                <Outlet />
            </div>
            <Intro />
            <Contact />
            <Footer />
        </div>
    )
}

export default Home;