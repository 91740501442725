import React, { memo, useEffect, useState } from 'react'
import { Select, InputReadOnly } from '../components'
import { apiGetPublicProvinces, apiGetPublicDistrict } from '../services'
import { useSelector } from 'react-redux'
const { getProvincesWithDetail, getDistricts } = require('vietnam-provinces');

const Address = ({ setPayload, invalidFields, setInvalidFields }) => {
    const { dataEdit } = useSelector(state => state.post);
    const [provinces, setProvinces] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [wards, setWards] = useState([]);
    const [province, setProvince] = useState('');
    const [district, setDistrict] = useState('');
    const [ward, setWard] = useState('');
    const [reset, setReset] = useState(false);
    const [houseNumberAndStreet, setHouseNumberAndStreet] = useState('');

    const removePrefix = (name) => name.replace(/^(Tỉnh|Thành phố)\s+/, '');

    useEffect(() => {
        const fetchProvinces = async () => {
            try {
                const response = await getProvincesWithDetail();

                if (!response || typeof response !== 'object') {
                    throw new Error('Response is null, undefined, or not an object');
                }

                const data = response.data || response; // Adjust this line based on actual response structure

                if (!data || typeof data !== 'object') {
                    throw new Error('Invalid response format');
                }

                const formattedProvinces = Object.values(data).map(province => ({
                    code: province.code,
                    name: removePrefix(province.name),
                    districts: Object.values(province.districts || {}).map(district => ({
                        code: district.code,
                        name: district.name,
                        wards: Object.values(district.wards || {}).map(ward => ({
                            code: ward.code,
                            name: ward.name
                        }))
                    }))
                }));

                // Sort provinces alphabetically by name
                const sortedProvinces = formattedProvinces.sort((a, b) => a.name.localeCompare(b.name));

                setProvinces(sortedProvinces);
            } catch (error) {
                console.error('Error fetching provinces:', error);
            }
        };
        fetchProvinces();
    }, []);

    useEffect(() => {
        if (dataEdit && dataEdit.address) {
            const addressArr = dataEdit.address.split(',');
            const addressProvince = removePrefix(addressArr[addressArr.length - 1].trim());
            const addressDistrict = addressArr.length > 1 ? addressArr[addressArr.length - 2].trim() : '';
            const addressWard = addressArr.length > 2 ? addressArr[addressArr.length - 3].trim() : '';

            const foundProvince = provinces.find(prov => prov.name === addressProvince);
            setProvince(foundProvince ? foundProvince.code : '');

            if (foundProvince) {
                const foundDistrict = foundProvince.districts.find(dist => dist.name === addressDistrict);
                setDistrict(foundDistrict ? foundDistrict.code : '');

                if (foundDistrict) {
                    const foundWard = foundDistrict.wards.find(w => w.name === addressWard);
                    setWard(foundWard ? foundWard.code : '');
                }
            }
        }
    }, [dataEdit, provinces]);

    useEffect(() => {
        const selectedProvince = provinces.find(item => item.code === province);
        if (selectedProvince) {
            setDistricts(selectedProvince.districts);
        } else {
            setDistricts([]);
        }
        setDistrict('');
        setReset(!province);
    }, [province, provinces]);

    useEffect(() => {
        const selectedDistrict = districts.find(item => item.code === district);
        if (selectedDistrict) {
            setWards(selectedDistrict.wards);
        } else {
            setWards([]);
        }
        setWard('');
        setReset(!district);
    }, [district, districts]);

    useEffect(() => {
        const selectedProvince = provinces.find(p => p.code === province);
        const selectedDistrict = districts.find(d => d.code === district);
        const selectedWard = wards.find(w => w.code === ward);

        setPayload(prev => ({
            ...prev,
            address: `${houseNumberAndStreet ? `${houseNumberAndStreet}, ` : ''}${selectedWard ? `${selectedWard.name}, ` : ''}${selectedDistrict ? `${selectedDistrict.name}, ` : ''}${selectedProvince ? selectedProvince.name : ''}`,
            province: selectedProvince ? selectedProvince.name : '',
            district: selectedDistrict ? selectedDistrict.name : '',
            ward: selectedWard ? selectedWard.name : ''
        }));
    }, [province, district, ward, houseNumberAndStreet, provinces, districts, wards, setPayload]);

    return (
        <div>
            <h2 className='font-semibold text-xl py-4'>Địa chỉ cho thuê</h2>
            <div className='flex flex-col gap-4'>
                <div className='flex items-center gap-4'>
                    <Select
                        setInvalidFields={setInvalidFields}
                        invalidFields={invalidFields}
                        type='province'
                        value={province}
                        setValue={setProvince}
                        options={provinces}
                        label='Tỉnh/Thành phố'
                    />
                    <Select
                        setInvalidFields={setInvalidFields}
                        invalidFields={invalidFields}
                        reset={reset}
                        type='district'
                        value={district}
                        setValue={setDistrict}
                        options={districts}
                        label='Quận/Huyện'
                    />
                    <Select
                        setInvalidFields={setInvalidFields}
                        invalidFields={invalidFields}
                        reset={reset}
                        type='ward'
                        value={ward}
                        setValue={setWard}
                        options={wards}
                        label='Phường/Xã'
                    />
                </div>
                <label className='font-medium'>Số nhà, Đường/Phố</label>
                <input
                    type="text"
                    placeholder="Số nhà, Đường/Phố"
                    value={houseNumberAndStreet}
                    onChange={(e) => setHouseNumberAndStreet(e.target.value)}
                    className='p-2 border border-gray-300 rounded outline-none'
                />
                <InputReadOnly
                    label='Địa chỉ chính xác'
                    value={`${houseNumberAndStreet ? `${houseNumberAndStreet}, ` : ''}${wards.find(w => w.code === ward)?.name || ''}${wards.find(w => w.code === ward) ? ', ' : ''}${districts.find(d => d.code === district)?.name || ''}${districts.find(d => d.code === district) ? ', ' : ''}${provinces.find(p => p.code === province)?.name || ''}`}
                />
            </div>
        </div>
    );
};


export default memo(Address)